.token-container {
  width: 35%;
  margin-left: auto;
  margin-right: auto;

  .token-input {
    margin-top: 15%;
  }
  .token-button {
    margin-top: 5%;
  }
}
